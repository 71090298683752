(function () {
    'use strict';

    angular.module('EntrakV5').config(routerConfig);

    function routerConfig($stateProvider, $urlRouterProvider) {
        console.log('config');
        // .state('fromLogin', {
        //     url: '/fromLogin/:access/:refresh',
        //     template: require('./login/login.html'),
        //     controller: 'loginController'
        // })
        $stateProvider.state('landing', {
            url: '/landing/:langCode?m?it?rt',
            controller: 'landingController'
        }).state('dashboard', {//TODOricky here
            url: '/dashboard',
            // template: require('./dashboard/dashboard.html'),
            // controller: 'dashboardController'
            template: require('./toReact/dummy.html'),
            controller: 'reactHomeController'
        }).state('old_dashboard', {
            url: '/old_dashboard',
            template: require('./dashboard/dashboard.html'),
            controller: 'dashboardController'
        }).state('toApp', {
            url: '/toApp',
            // template: require('./dashboard/dashboard.html'),
            controller: 'toAppController'
        }).state('selectFloor', {
            url: '/selectFloor',
            template: require('./toReact/dummy.html'),
            controller: 'reactLocationController'
        }).state('location', {
            url: '/location',
            template: require('./toReact/dummy.html'),
            controller: 'reactLocationController'
        }).state('old_selectFloor', {
            url: '/old_selectFloor',
            template: require('./selectFloor/selectFloor.html'),
            controller: 'selectFloorController'
        }).state('old_location', {
            url: '/old_location/:floorId',
            template: require('./location/location.html'),
            controller: 'locationController'
        }).state('selectHeatmap', {
            url: '/selectHeatmap',
            template: require('./selectFloor/selectFloor.html'),
            controller: 'selectFloorController'
        }).state('company', {
            url: '/company',
            template: require('./company/company.html'),
            controller: 'companyController'
        }).state('scene', {
            url: '/scene',
            template: require('./scene/scene.html'),
            controller: 'sceneController'
        }).state('schedule', {
            url: '/schedule',
            template: require('./schedule/schedule.html'),
            controller: 'scheduleController'
        }).state('calendar', {
            url: '/calendar',
            template: require('./schedule/calendar.html'),
            controller: 'calendarController'
        }).state('log', {
            url: '/log',
            template: require('./log/log.html'),
            controller: 'logController'
        }).state('heatmap', {
            url: '/heatmap/:floorId?deviceId',
            template: require('./log/heatmap.html'),
            controller: 'heatmapController'
        }).state('account', {
            url: '/account',
            template: require('./toReact/dummy.html'),
            controller: 'reactAccountController'
        }).state('old_account', {
            url: '/old_account',
            template: require('./account/account.html'),
            controller: 'accountController'
        }).state('insight', {
            url: '/insight',
            template: require('./toReact/dummy.html'),
            controller: 'reactInsightController'
        }).state('newDashboard', {
            url: '/newDashboard',
            template: require('./toReact/dummy.html'),
            controller: 'reactDashboardController'
        }).state('profile', {
            url: '/profile',
            template: require('./toReact/dummy.html'),
            controller: 'reactProfileController'
        }).state('old_profile', {
            url: '/old_profile',
            template: require('./profile/profile.html'),
            controller: 'profileController'
        });

        $urlRouterProvider.otherwise('/dashboard');
    }

})();
