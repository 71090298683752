import './app.less';

(function () {
    'use strict';
//"angular-mousewheel": "^1.0.5"
    angular.module('EntrakV5', ['ui.router', 'kendo.directives', 'ngDraggable', 'slickCarousel']).run(['Service', '$state', runBlock]);

    function runBlock(Service, $state) {
        console.log('run');

        Service.initLangCode();

    }
}());
