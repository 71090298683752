(function() {
    'use strict';

    angular.module('EntrakV5').constant('KEY', {
        // tenantHasScene: ["3018539f-9e2d-52f8-945f-d1a8c913e7e4", "fabaf468-4247-5c93-8841-feaf934584f8"],
        en: "en",
        zh: "zh",
        cn: "cn",
        ignore: "IGNORE",
        accessTokenName: "appAccessToken",
        refreshTokenName: "appRefreshToken",
        scrollBarWidth: (function(){
            var outer = document.createElement("div");
            outer.style.visibility = "hidden";
            outer.style.overflow = "scroll";
            outer.style.width = "100px";
            document.body.appendChild(outer);

            var inner = document.createElement("div");
            inner.style.width = "100%";
            outer.appendChild(inner);

            var width = outer.offsetWidth - inner.offsetWidth;
            outer.parentNode.removeChild(outer);

            return width;
        })(),
        recentControlListLength: 10,
        volumeLvMax: 10,
        minBeforeShowExtend: 30,
        timerDefault: 30,
        timerStep: 5,
        defaultTimerMaxHr: 2,
        scalePerZoom: 1.3,
        zoomMax: 2,
        zoomMin: 0.2,
        minTempDuration: 10,
        maxTempDuration: 20,
        minDeltaTemp: 1,
        maxDeltaTemp: 4,
        minDefaultTemp: 16,
        maxDefaultTemp: 28,
        maxCo2ppm: 4000,
        minHumidity: 0,
        maxHumidity: 100,
        minTemp: -50,
        maxTemp: 50,
    }).constant('APIKEY', {
        defaultGroup: "default",
        allOn: "ALL_ON",
        allOff: "ALL_OFF",
        mixOnOff: "MIX",
        pageSize: 20,
        fanSpeed: {
            high: "high",
            medium: "medium",
            low: "low",
            auto: "auto",
        },
        provider: {
            google: "GOOGLE",
            microsoft: "MICROSOFT",
            email: "EMAIL",
        },
        role: {
            super: "SUPERUSER",
            admin: "ADMIN",
            user: "USER",
            zoneAdmin: "ZONE_ADMIN",
            landlordUser: "LANDLORD_USER",
            unknown: "UNKNOWN_ROLE",
            insight: "DASHBOARD_ADMIN",
        },
        aclRole: {
            unknown: "UNKNOWN",
            super: "SUPERUSER",
            landlordUser: "LANDLORD_USER",
            landlordAdmin: "LANDLORD_ADMIN",
            tenantUser: "TENANT_USER",
            tenantAdmin: "TENANT_ADMIN",
            zoneAdmin: "ZONE_ADMIN",
            dashboardAdmin: "DASHBOARD_ADMIN",
            dashboardUser: "DASHBOARD_USER",
            student: "STUDENT"
        },
        invite: {
            pending: "PENDING",
            active: "ACTIVE",
        },
        action: {
            checkin: "CHECKIN",
            checkout: "CHECKOUT",
            extend: "EXTEND",
            warmer: "WARMER",
            cooler: "COOLER",
            normal: "NORMAL",
            blink: "BLINK",
            on: "ON",
            off: "OFF",
            unknown: "UNKNOWN",
            reinstate: "REINSTATE",
            allOff: "ZONEOFF",
            allOn: "ZONEON",
            dim: "DIMMING",
            setPoint: "SETPOINT",
        },
        triggerBy: {
            invalid: "INVALID",
            user: "USER",
            timetable: "TIMETABLE",
            api: "API",
            scene: "SCENE",
            reservation: "RESERVATION",
            condition: "CONDITION",
            card: "CARD",
            multigang: "MULTIGANG",
            visitor: "VISITOR",
            bookingSystem: "BOOKING_SYSTEM",
            dummyRecord: "STATUS_IDENT"
        },
        logic: {
            greater: "GTE",
            lower: "LTE",
            equal: "EQ",
            noLogic: "",
        },
        scheduleAction: {
            auto: "AUTO",
            manual: "MANUAL",
            noop: "NOOP",
        },
        status: {
            warming: "WARMING",
            cooling: "COOLING",
            error: "ERROR",
            on: "ON",
            off: "OFF",
            inprogress: "INPROGRESS",
        },
        applicationType: {    //must sync with Inv
            light: "LIGHT",
            aircon: "AIRCON",
            button: "BUTTON",
            gateway: "GATEWAY",
            iaqSensor: "IAQ_SENSOR",
            lightSensor: "LIGHT_SENSOR",
            motionSensor: "OCCUPANCY_SENSOR",
            switch: "SWITCH",
            door: "DOOR",
            fan: "FAF",
            energyMeter: "ENERGY_METER",
            waterMeter: "WATER_METER",
            thermometer: "THERMOMETER",
            unknown: "UNKNOWN",
        },
        applicationTypeInv: {
            LIGHT: "light",
            AIRCON: "aircon",
            BUTTON: "button",
            GATEWAY: "gateway",
            IAQ_SENSOR: "iaqSensor",
            LIGHT_SENSOR: "lightSensor",
            OCCUPANCY_SENSOR: "motionSensor",
            SWITCH: "switch",
            DOOR: "door",
            FAF: "fan",
            ENERGY_METER: "energyMeter",
            WATER_METER: "waterMeter",
            THERMOMETER: "thermometer",
            UNKNOWN: "unknown",
        },
        nodeType: {    //must sync with Inv
            company: "COMPANY",
            // floor: "FLOOR",
            zone: "ZONE",
            room: "ROOM",
            workstation: "WORKSTATION",
            corridor: "CORRIDOR",
        },
        nodeTypeInv: {
            COMPANY: "company",
            // FLOOR: "floor",
            ZONE: "zone",
            ROOM: "room",
            WORKSTATION: "workstation",
            CORRIDOR: "corridor",
        },
        days: ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY'],//must start from sunday (align with kendo)
        pwdPolicy: {
            general: "GENERAL",
            advanced: "ADVANCED"
        },
    });
})();
