(function() {
	"use strict";

	angular.module("EntrakV5").directive("translate", ["Service", "$compile", function(Service, $compile) {
		return {
			restrict: "A",
			scope: {
				translate: "@",
				transParams: "=",
			},
			link: function(scope, elem, attr) {
				if (scope.translate) {
					scope.$watch("translate", function(n) {
						elem.html(Service.translate(n, scope.transParams));
					});
				}
				if (scope.transParams) {
					var key = elem.text();
					scope.$watch("transParams", function(n) {
						elem.html(Service.translate(scope.translate ? scope.translate : key,
							n));
					});
				}
				if (!scope.translate && !scope.transParams)
					elem.html(Service.translate(elem.text(), scope.transParams));
			},
		}
	}]).directive("etScrolled", [function() {
		return {
			restrict: "A",
			scope: {
				etScrolled: "&", //function(scrollElm)
			},
			link: function(scope, element, attrs) {
				scope.scrolled = function() {
					scope.etScrolled();
				}
				element.on("scroll", scope.scrolled);
				scope.$on("$destroy", function() {
					element.off("scroll", scope.scrolled);
				});
			},
		}
	}]).directive("etTimer", ["Service", "$compile", "KEY", function(Service, $compile, KEY) {
		return {
			restrict: "E",
			scope: {
				etChange: "&", //function($value)
				etModel: "=", //need an object for 2-way binding to work, percentage(0-100)
				etModelField: "@", //default name: value
				etWidth: "@",
				etRadius: "@",
				etMaxHour: "@",
			},
			link: function(scope, element, attrs) {
				element.addClass("etTimer");

				if (!scope.etModelField)
					scope.etModelField = "value";
				if (!scope.etMaxHour)
					scope.etMaxHour = KEY.defaultTimerMaxHr;
				scope.etMaxMinute = scope.etMaxHour * 60;

				var changeAction = function(e) {
					scope.$apply(function() {
						if (scope.etModel) {
							if (e.value > scope.etMaxMinute) {
								console.error("etTimer invalid model value:", scope
									.etModel[scope.etModelField]);
								scope.etModel[scope.etModelField] = scope.etMaxMinute;
							} else if (e.value < 0) {
								console.error("etTimer invalid model value:", scope
									.etModel[scope.etModelField]);
								scope.etModel[scope.etModelField] = 0;
							} else {
								scope.etModel[scope.etModelField] = e.value;
							}
						}
						if (typeof scope.etChange === "function")
							scope.etChange({
								$value: e.value
							});
					});
				}

				element.roundSlider({
					mouseScrollAction: true,
					handleShape: "round",
					width: scope.etWidth ? scope.etWidth : 24,
					radius: scope.etRadius ? scope.etRadius : 120,
					value: 0,
					min: KEY.timerStep,
					step: KEY.timerStep,
					max: scope.etMaxMinute,
					startAngle: 90,
					endAngle: "+360",
					sliderType: "min-range",
					handleSize: "+4",
					editableTooltip: false,
					tooltipFormat: function(args) {
						return "<div><div class='minuteValue'>" + args.value +
							"</div>" + "<div class='minuteLbl'>" + Service.translate(
								"label.minutes") + "</div></div>";
					},
					drag: changeAction,
					change: changeAction,
				});

				var control = element.data("roundSlider");
				scope.$watch("etModel[etModelField]", function(n, o) {
					control.setValue(n);
				});
			},
		}
	}]).directive("etVolume", ["$compile", "KEY", "APIKEY", function($compile, KEY, APIKEY) {
		return {
			restrict: "E",
			scope: {
				etChange: "&", //function($value)
				etModel: "=", //need an object for 2-way binding to work, percentage(etMinValue-100)
				etModelField: "@", //default name: dimmingLevel
				etApplicationType: "@", //default: LIGHT
				etDisabled: "=", //default: false
				etLevel: "@", //num of steps, default 5
				etAllowZero: "@", //min value is zero or not
				etMinHeightPercent: "@", //height for the shortest bar, percent of the highest one, default 40%
			},
			link: function(scope, element, attrs) {
				element.addClass("etVolume");

				if (!scope.etModelField)
					scope.etModelField = "dimmingLevel";
				if (!scope.etLevel || scope.etLevel < 2)
					scope.etLevel = KEY.volumeLvMax;
				var stepPercent = 100 / scope.etLevel;
				var minPercent = scope.etAllowZero ? 0 : stepPercent;
				var iconType = APIKEY.applicationTypeInv[scope.etApplicationType];
				if (!iconType)
					iconType = APIKEY.applicationTypeInv.LIGHT;

				if (!scope.etMinHeightPercent || scope.etMinHeightPercent < 0 || scope
					.etMinHeightPercent > 100)
					scope.etMinHeightPercent = 40;
				var heightStep = (100 - scope.etMinHeightPercent) / (scope.etLevel - 1);

				scope._etClick = function(value) {
					if (scope.etDisabled || value == scope.etModel[scope.etModelField]) return;

					scope.etModel[scope.etModelField] = value;
					if (typeof scope.etChange === "function")
						scope.etChange({
							$value: value
						});
				}

				scope.$watch("etModel[etModelField]", function(n) {
					if (n > 100 || n < stepPercent) {
						console.error("etVolume invalid model value:", n);
						scope.etModel[scope.etModelField] = stepPercent;
					}
				});

				var html = "";
				for (var i = 0; i < scope.etLevel; i++) {
					if (i != 0)
						html = "<div></div>" + html;
					html = '<div class="volumeBar" ng-class="{on: etModel[etModelField] >= ' +
						(100 - i * stepPercent) +
						', disabled: etDisabled}" ng-click="_etClick(' +
						(100 - i * stepPercent) +
						')" style="height: ' +
						(100 - i * heightStep) +
						'%;"></div>' +
						html;
				}
				html += '<div class="applicationType ' + iconType + '"></div>';
				var elm = $compile(html)(scope);
				element.append(elm);
			},
		}
	}]).directive("etBreadcrumb", ["$compile", function($compile) {
		return {
			restrict: "E",
			scope: {
				etModel: "=", // array of obj
				etNavigate: "&", //function(...., $node, $i)
				etBack: "&", //function(...., $node, $i)
				etNameField: "@", //default: name
				etReadOnly: "=",
			},
			link: function(scope, element, attrs) {
				element.addClass("etBreadcrumb");

				if (!scope.etNameField)
					scope.etNameField = "name";

				scope.navigate = function($i, $node) {
					scope.etModel.splice($i + 1);
					scope.etNavigate({
						$i: $i,
						$node: $node
					});
				}
				scope.back = function() {
					scope.etModel.pop();
					scope.etBack({
						$i: scope.etModel.length - 1,
						$node: scope.etModel[scope.etModel.length - 1],
					});
				}
				var html =
					'<button ng-if="etModel.length > 1 && !etReadOnly" ng-click="back()" class="backBtn"></button>' +
					'<div ng-repeat="node in etModel" ng-class="{leafNode: $last, rootNode: $first, readOnly: etReadOnly}">' +
					'<span ng-bind="node.' +
					scope.etNameField +
					'" ng-click="($last || etReadOnly) ? false : navigate($index, node)"></span>' +
					"</div>";
				var elm = $compile(html)(scope);
				element.append(elm);
			},
		}
	}]).directive("etCalendar", ["Service", "KEY", "$compile", function(Service, KEY, $compile) {
		return {
			restrict: "E",
			scope: {
				// etModel: {
				// 	(etModelField): [{ id timestamp }]
				//	(etLabelField): [{ name dates[{ timestamp }] }]
				//	addedDates(return): [timestamp]
				//	removedDates(return): [{ id timestamp }]
				//	isLoading: default false
				//	initCalendar(return): func
				// }
				etModel: "=", //need an object for 2-way binding to work
				etModelField: "@", //default: selectedDates (unixTimestamp at 00:00:00)
				etLabelField: "@", //default: specialDays
				etLoading: "=", //show loading
				etReadOnly: "=", //default: false
				etChangeYear: "&?", //function($year)
			},
			link: function(scope, element, attrs) {
				element.addClass("etCalendar");

				scope.padding = {
					"padding-right": KEY.scrollBarWidth + "px",
				}
				if (!scope.etModelField)
					scope.etModelField = "selectedDates";
				if (!scope.etLabelField)
					scope.etLabelField = "specialDays";
				scope.dataMap = {};
				scope.labelMap = {};

				scope.etModel.initCalendar = function() {
					scope.etModel.addedDates = [];
					scope.etModel.removedDates = [];
					scope.dataMap = {};
					var selectedDates = scope.etModel[scope.etModelField];
					for (var i = 0; i < selectedDates.length; i++) {
						scope.dataMap[selectedDates[i].timestamp] = true;
					}
				}
				scope.etModel.initCalendar();

				//update label display, trigger when etLabelField changed
				scope.$watch("etModel[etLabelField]", function(n, o) {
					if (n) {
						scope.labelMap = {};
						for (var i = 0; i < n.length; i++) {
							if (n[i].dates) {
								for (var j = 0; j < n[i].dates.length; j++) {
									var ts = n[i].dates[j].timestamp;
									if (!scope.labelMap[ts])
										scope.labelMap[ts] = [];
									scope.labelMap[ts].push(n[i].name);
								}
							} else if (n[i].timestamp) {
								var ts = n[i].timestamp;
								if (!scope.labelMap[ts])
									scope.labelMap[ts] = [];
								scope.labelMap[ts].push(n[i].name);
							}
						}
					}
				}, true);

				scope.selectDate = function(timestamp) {
					if (scope.etReadOnly)
						return;
					if (scope.dataMap[timestamp]) {
						//deselect
						var addInd = scope.etModel.addedDates.indexOf(timestamp);
						var deselectedDate = Service.deleteArrItem(scope.etModel[scope
							.etModelField], timestamp, "timestamp");
						if (addInd != -1) {
							scope.etModel.addedDates.splice(addInd, 1);
						} else {
							scope.etModel.removedDates.push(deselectedDate);
						}
					} else {
						//select
						var selectedDate = Service.deleteArrItem(scope.etModel.removedDates,
							timestamp, "timestamp");
						if (selectedDate) {
							scope.etModel[scope.etModelField].push(selectedDate);
						} else {
							scope.etModel[scope.etModelField].push({
								id: null,
								timestamp: timestamp
							});
							scope.etModel.addedDates.push(timestamp);
						}
					}
					scope.dataMap[timestamp] = !scope.dataMap[timestamp];
				}

				scope.changeYear = function(isNext) {
					if (isNext) {
						scope.currentYear++;
					} else {
						scope.currentYear--;
					}
					if (scope.etChangeYear)
						scope.etChangeYear({
							$year: scope.currentYear
						});

					var tmpDate = new Date(Date.UTC(scope.currentYear, 0,
					1)); //scope.currentYear, 0);
					kendo.date.setDayOfWeek(tmpDate, 0, -1);
					scope.days = [];
					for (var i = 0; i < 371; i++) {
						// 7 x 53 weeks
						var ts = Service.getUnixTimestamp(tmpDate);
						scope.days.push({
							date: tmpDate,
							isOddMonth: tmpDate.getMonth() & 1,
							ts: ts,
						});
						tmpDate = kendo.date.nextDay(tmpDate);
					}
				}

				scope.weekdayNames = window.kendo.cultures.current.calendar.days
				.namesAbbr; //start from sunday
				scope.currentYear = new Date().getFullYear() + 1;
				scope.changeYear();

				var html =
					'<div ng-style="padding" class="yearContainer"><button class="leftBtn" ng-click="changeYear()"></button><span>{{days[10].date|dateFmt:"yyyy"}}</span><button ng-click="changeYear(true)" class="rightBtn"></button></div>' +
					'<div ng-style="padding" class="labelContainer"><div ng-repeat="day in weekdayNames">{{day}}</div></div>' +
					'<div class="cellContainer" ng-class="{readOnly: etReadOnly}">' +
					'<div ng-repeat="d in days" ng-click="selectDate(d.ts)" ng-class="{selected: dataMap[d.ts], altBg: d.isOddMonth}">' +
					'<div>{{d.date|dateFmt:"short"}}</div>' +
					'<div ng-if="(lbls = labelMap[d.ts])">{{lbls.join(", ")}}</div>' +
					"</div>" +
					'<span class="loading" ng-show="etLoading"></span>' +
					"</div>";

				var elm = $compile(html)(scope);
				element.append(elm);
			},
		}
	}]).directive("etSchedule", ["$compile", "$timeout", "Service", "APIKEY", function($compile, $timeout,
		Service, APIKEY) {
		//assume all sections' start and end time are within the same date viewing by user browser's default timezone
		return {
			restrict: "E",
			scope: {
				// etModel: {
				// 	(etModelField): {
				//		timeslots: [{ id, weekday, start{hour,minute,action,index(return)}, end{hour,minute,action,index(return)} }]
				//		deletedTimeslots(return): [{ id, delete }]
				// 	},	start.action: always follow user selection, end.action: noop(if connected to the next timeslot) auto(else), weekday: wil be an ID if has etCustomDay
				//	initScheduleData(return): func(alreadySorted, custDayDataList[{id name dayEndAction}])
				//	addCustomDay(return): func(id, name, dayEndAction)
				//	removeCustomDay(return): func(id)
				//	custDayList(return): [{ id name dayEndAction reachDayEnd }]
				// }
				etModel: "=", //need an object for 2-way binding to work
				etModelField: "@", //default: schedule
				etAutoOnly: "=", //default: false
				etHideLegend: "=", //default: false
				etLongDayName: "=", //default: false
				etCustomDay: "=", //default: false (if false then use mon-sun, else use addCustomDay to add), cannot toggle this field
			},
			link: function(scope, element, attrs) {
				element.addClass("etSchedule");
				if (attrs.id) {
					scope.instanceId = attrs.id;
				} else {
					scope.instanceId = "schedule" + (Math.random() + "").replace("0.", "");
					element.attr("id", scope.instanceId);
				}

				scope.useCustDay = scope.etCustomDay; //prevent changing etCustomDay dynamically

				if (!scope.etModelField)
					scope.etModelField = "schedule";

				scope.cellWidth = 10; //match css
				var scollPx = 200;
				var cellDuration = 15; //15, 30, 60

				scope.tsMode = APIKEY.scheduleAction;

				scope.popupData = { //for edit popup
					sectionModes: [{
						value: scope.tsMode.auto,
						text: Service.translate("scheduleCtrl.autoDesc"),
					}, {
						value: scope.tsMode.manual,
						text: Service.translate("scheduleCtrl.manualDesc"),
					}],
				}

				scope.cellIndexes = [];
				for (var i = 0; i < (24 * 60) / cellDuration; i++)
					scope.cellIndexes.push(i);

				scope.timeLbls = ["00:00", "", "02:00", "", "04:00", "", "06:00", "", "08:00", "",
					"10:00", "", "12:00", "", "14:00", "", "16:00", "", "18:00", "", "20:00",
					"", "22:00", ""
				];
				scope.scrollValue = 0;

				if (scope.useCustDay) {
					scope.etModel.custDayList = []; //{ id name dayEndAction reachDayEnd }
					scope.days = scope.etModel.custDayList;
				} else {
					scope.$watch("etLongDayName", function(n, o) {
						scope.days = APIKEY.days.map(function(v, i) {
							return {
								id: v,
								name: window.kendo.cultures.current.calendar.days[
									n ? "names" : "namesAbbr"][i],
							}
						});
						scope.days.push(scope.days.shift());
					});
				}

				//custDayDataList: [{id name dayEndAction}]
				scope.etModel.initScheduleData = function(alreadySorted, custDayDataList) {
					var sch = scope.etModel[scope.etModelField];
					sch.deletedTimeslots = [];
					sch.timeslots.forEach(function(ts) {
						var time = ts.start.hour * 60 + parseInt(ts.start.minute, 10);
						ts.start.index = time / cellDuration;
						time = ts.end.hour * 60 + parseInt(ts.end.minute, 10);
						ts.end.index = time / cellDuration;
					});

					if (scope.useCustDay) {
						scope.etModel.custDayList = [];
						if (custDayDataList) {
							custDayDataList.forEach(function(d) {
								scope.etModel.addCustomDay(d.id, d.name, d
								.dayEndAction);
							});
							scope.updateCustDayEndAction();
						}
						scope.days = scope.etModel.custDayList;
					}

					if (!alreadySorted)
						scope.sortTimeslot();

					//init scroll value
					$timeout(function() {
						scope.scrollValue = 0;
						scope.scroll();
					});
				}

				scope.etModel.addCustomDay = function(id, name, dayEndAction) {
					if (scope.useCustDay) {
						scope.etModel.custDayList.push({
							id: id,
							name: name,
							dayEndAction: dayEndAction,
							reachDayEnd: false,
						});
					}
				}
				scope.etModel.removeCustomDay = function(id) {
					if (scope.useCustDay) {
						Service.deleteArrItem(scope.etModel.custDayList, id);
						scope.removeTimeslotByDay(id);
						scope.dayPopup.close();
					}
				}

				scope.scroll = function(toLeft) {
					if (toLeft) {
						scope.scrollValue += scollPx;
						if (scope.scrollValue > 0)
							scope.scrollValue = 0;
					} else {
						var diff = scope.cellWidth * scope.cellIndexes.length - element.find(
							".timeBlock").width();
						if (diff < 0)
							return;
						scope.scrollValue -= scollPx;
						if (scope.scrollValue < -diff)
							scope.scrollValue = -diff;
					}
				}

				scope.getTimeStr = function(timeslotTime) {
					return Service.timeslotTimeToStr(timeslotTime);
				}
				scope.createTimeslotTime = function(cellIndex, action) {
					var obj = {
						action: action,
						index: cellIndex
					}
					var time = cellIndex * cellDuration;
					obj.hour = Math.floor(time / 60);
					obj.minute = time % 60;
					return obj;
				}

				scope.getTimeslot = function(dayKey, startIndex) {
					var ts = scope.etModel[scope.etModelField].timeslots;
					for (var i = 0; i < ts.length; i++) {
						if (ts[i].weekday === dayKey && ts[i].start.index === startIndex)
						return ts[i];
					}
				}

				scope.sortTimeslot = function() {
					//if use custom day, sort by the order of custom day list
					Service.sortSchTimeslot(scope.etModel[scope.etModelField], scope.etModel
						.custDayList);
				}

				scope.updateEndAction = function() {
					var timeslots = scope.etModel[scope.etModelField].timeslots;
					if (timeslots.length > 1) {
						var prevTimeslot = scope.useCustDay ? {
							start: {},
							end: {}
						} : timeslots[timeslots.length - 1];
						for (var i = 0; i < timeslots.length; i++) {
							var curTimeslot = timeslots[i];
							if (curTimeslot.start.action === APIKEY.scheduleAction.auto) {
								//only if two timeslots are connected and the second one is auto on, the first timeslot should set end.action = noop
								if (curTimeslot.weekday === prevTimeslot.weekday && curTimeslot
									.start.index == prevTimeslot.end.index) {
									//same day
									prevTimeslot.end.action = APIKEY.scheduleAction.noop;
								} else if (curTimeslot.weekday === Service.getNextDayKey(
										prevTimeslot.weekday) && curTimeslot.start.index == 0 &&
									prevTimeslot.end.index == scope.cellIndexes.length) {
									//overnight
									prevTimeslot.end.action = APIKEY.scheduleAction.noop;
								} else {
									prevTimeslot.end.action = APIKEY.scheduleAction.auto;
								}
							} else {
								prevTimeslot.end.action = APIKEY.scheduleAction.auto;
							}
							prevTimeslot = curTimeslot;
						}
						if (scope.useCustDay) timeslots[timeslots.length - 1].end.action =
							APIKEY.scheduleAction.auto;
					} else if (timeslots.length == 1) {
						timeslots[0].end.action = APIKEY.scheduleAction.auto;
					}
					scope.updateCustDayEndAction();
				}
				scope.updateCustDayEndAction = function() {
					var timeslots = scope.etModel[scope.etModelField].timeslots;
					if (scope.useCustDay && timeslots.length >= 0) {
						scope.etModel.custDayList.forEach(function(d) {
							d.reachDayEnd = false;
						});
						for (var i = 0; i < timeslots.length; i++) {
							if (timeslots[i].end.index == scope.cellIndexes.length) {
								var dayData = Service.getArrItem(scope.etModel.custDayList,
									timeslots[i].weekday);
								dayData.reachDayEnd = true;
								if (dayData.dayEndAction)
									timeslots[i].end.action = dayData.dayEndAction;
							}
						}
						scope.etModel.custDayList.forEach(function(d) {
							if (!d.reachDayEnd)
								d.dayEndAction = null;
						});
					}
				}

				//draggingTimeslot(optional): will skip checking for this timeslot
				scope.isOverlap = function(dayKey, startIndex, endIndex, draggingTimeslot) {
					var timeslots = scope.etModel[scope.etModelField].timeslots;
					for (var i = 0; i < timeslots.length; i++) {
						var ts = timeslots[i];
						if (ts.weekday === dayKey) {
							if (ts !== draggingTimeslot) {
								if (ts.end.index > startIndex && ts.start.index < endIndex)
									return true;
							}
						}
					}
					return false;
				}

				scope.addTimeslot = function(dayKey, startIndex, endIndex) {
					if (scope.isOverlap(dayKey, startIndex, endIndex))
						return;

					scope.etModel[scope.etModelField].timeslots.push({
						weekday: dayKey,
						start: scope.createTimeslotTime(startIndex, scope.tsMode.auto),
						end: scope.createTimeslotTime(endIndex),
					});

					scope.sortTimeslot();
					scope.updateEndAction();
				}
				scope.moveTimeslot = function(dayKey, startIndex, endIndex, draggingTimeslot) {
					if (scope.isOverlap(dayKey, startIndex, endIndex, draggingTimeslot))
						return;

					var timeslots = scope.etModel[scope.etModelField].timeslots;
					draggingTimeslot.start = scope.createTimeslotTime(startIndex,
						draggingTimeslot.start.action);
					draggingTimeslot.end = scope.createTimeslotTime(endIndex);
					draggingTimeslot.weekday = dayKey;

					scope.sortTimeslot();
					scope.updateEndAction();
				}
				scope.removeTimeslot = function(timeslot) {
					var sch = scope.etModel[scope.etModelField];
					sch.timeslots.splice(sch.timeslots.indexOf(timeslot), 1);
					scope.updateEndAction();
					if (timeslot.id) {
						sch.deletedTimeslots.push({
							id: timeslot.id,
							weekday: timeslot.weekday,
							delete: true,
						});
					}
				}
				scope.removeTimeslotByDay = function(dayKey) {
					var sch = scope.etModel[scope.etModelField];
					for (var i = sch.timeslots.length - 1; i >= 0; i--) {
						if (sch.timeslots[i].weekday === dayKey) {
							var deleted = sch.timeslots.splice(i, 1)[0];
							if (deleted) {
								sch.deletedTimeslots.push({
									id: deleted.id,
									weekday: dayKey,
									delete: true,
								});
							}
						}
					}
					scope.updateEndAction();
				}

				//drag & drop timeSection
				scope.timeslotOnDrop = function(toDayKey, toStartIndex, data) {
					if (data && data.ts && data.id === scope.instanceId) {
						data = data.ts;
						if (toDayKey === data.weekday && toStartIndex === data.start.index)
							return;
						var toEndIndex = toStartIndex + data.end.index - data.start.index;
						if (toEndIndex <= scope.cellIndexes.length)
							scope.moveTimeslot(toDayKey, toStartIndex, toEndIndex, data);
					} else {
						//sometimes may trigger the event two times, ignore the second one
					}
				}

				//for cell interaction
				var dummySection = null;
				if (!interact.isSet("#" + scope.instanceId + " .timeRow>div")) {
					interact("#" + scope.instanceId + " .timeRow>div").resizable({
						edges: {
							right: "div",
						},
						snap: {
							targets: [interact.createSnapGrid({
								x: scope.cellWidth
							})],
						},
					}).on("resizestart", function(event) {
						if ($(event.target).find(".timeSection").length) {
							dummySection = null;
						} else {
							dummySection = $('<div class="dummyTimeSection"></div>')
								.appendTo(event.target);
						}
					}).on("resizeend", function(event) {
						if (dummySection != null) {
							var timeRow = $(event.target).parent();
							var dayKey = timeRow.data("day-key");
							var startIndex = timeRow.children().index(event.target);
							var endIndex = Math.floor(dummySection.width() / scope
								.cellWidth) + startIndex + Math.round((dummySection
								.width() % scope.cellWidth) / scope.cellWidth);
							if (endIndex > scope.cellIndexes.length) endIndex = scope
								.cellIndexes.length;
							if (dayKey && startIndex < endIndex) {
								scope.$apply(function() {
									scope.addTimeslot(dayKey, startIndex, endIndex);
								});
							}
							dummySection.remove();
						}
					}).on("resizemove", function(event) {
						if (dummySection != null)
							dummySection.width(event.rect.width + "px");
					});
				}

				//for timeslot interaction
				var maxResizeLength = null;
				var resizingTimeslot = null;
				var resizingStartIndex = null;
				if (!interact.isSet("#" + scope.instanceId + " .timeRow .timeSection")) {
					interact("#" + scope.instanceId + " .timeRow .timeSection").resizable({
						edges: {
							right: ".resizeHandle",
						},
						snap: {
							targets: [interact.createSnapGrid({
								x: scope.cellWidth
							})],
						},
					}).on("resizestart", function(event) {
						resizingStartIndex = parseInt(event.target.getAttribute(
							"data-start-index"), 10);
						resizingTimeslot = scope.getTimeslot($(event.target).parent()
							.parent().data("day-key"), resizingStartIndex);

						if (resizingTimeslot && resizingStartIndex != null) {
							var ts = scope.etModel[scope.etModelField].timeslots;
							var nextStartIndex = ts.indexOf(resizingTimeslot) + 1;
							if (ts[nextStartIndex] && ts[nextStartIndex].weekday ===
								resizingTimeslot.weekday) {
								maxResizeLength = ts[nextStartIndex].start.index;
							} else {
								maxResizeLength = scope.cellIndexes.length;
							}
							maxResizeLength -= resizingStartIndex;
						}
					}).on("resizemove", function(event) {
						var cellCourt = Math.round(event.rect.width / scope.cellWidth);
						if (cellCourt < 1) {
							cellCourt = 1;
						} else if (cellCourt > maxResizeLength) {
							cellCourt = maxResizeLength;
						}

						if (resizingTimeslot) {
							scope.$apply(function() {
								resizingTimeslot.end.index = cellCourt +
									resizingStartIndex;
							});
						}
					}).on("resizeend", function(event) {
						if (resizingTimeslot) {
							scope.$apply(function() {
								resizingTimeslot.end = scope.createTimeslotTime(
									resizingTimeslot.end.index);
								scope.updateEndAction();
							});
						}
					});
				}

				element.on("$destroy", function() {
					if (interact.isSet("#" + scope.instanceId + " .timeRow>div"))
						interact("#" + scope.instanceId + " .timeRow>div").unset();
					if (interact.isSet("#" + scope.instanceId + " .timeRow .timeSection"))
						interact("#" + scope.instanceId + " .timeRow .timeSection").unset();
				});

				//click timeSection
				scope.timeslotOnClick = function(dayKey, startIndex, timeslot) {
					scope.popupData.timeslot = timeslot;
					var startTime = new Date();
					startTime.setHours(timeslot.start.hour);
					startTime.setMinutes(timeslot.start.minute);
					var endTime = new Date();
					endTime.setHours(timeslot.end.hour);
					endTime.setMinutes(timeslot.end.minute);
					scope.popupData.timeLabel = Service.timeFmt(startTime) + " - " + Service
						.timeFmt(endTime);
					scope.popupData.popupStartIndex = startIndex;
					scope.popupData.sectionMode = timeslot.start.action;
					scope.editPopup.close();
					scope.editPopup.setOptions({
						anchor: element.find(".timeRow[data-day-key='" + dayKey +
							"'] .timeSection[data-start-index='" + startIndex +
							"'] .clickHandle"),
					});
					setTimeout(function() {
						//wait after popup closed
						scope.editPopup.open();
					}, 50);
				}

				scope.popupDeleteTimeslot = function() {
					scope.removeTimeslot(scope.popupData.timeslot);
					scope.editPopup.close();
				}
				scope.popupSaveTimeslot = function() {
					scope.popupData.timeslot.start.action = scope.popupData.sectionMode;
					scope.editPopup.close();
				}

				scope.editPopupOpt = {
					origin: "top center",
					position: "bottom center",
					appendTo: element,
					animation: {
						open: {
							effects: "slideIn:up",
						},
					},
				}

				var popupHtml =
					'<div kendo-popup="editPopup" class="editPopup" k-options="editPopupOpt">' +
					'<div class="rowStart">{{popupData.timeLabel}}</div>' +
					'<div class="colStart" ng-if="!etAutoOnly"><label class="radio" ng-repeat="mode in popupData.sectionModes"><input type="radio" ng-model="popupData.sectionMode" value="{{mode.value}}"/><div></div><div ng-class="{manual: mode.value == tsMode.manual}" class="legendColor"></div><span>{{mode.text}}</span></label></div>' +
					'<div class="rowBetween">' +
					'<button class="normalBtn plain redColor" ng-click="popupDeleteTimeslot()" translate>button.delete</button>' +
					'<div><button class="normalBtn plain greyColor" ng-click="editPopup.close()" translate>button.cancel</button><button class="normalBtn lightColor" ng-if="!etAutoOnly" ng-click="popupSaveTimeslot()" translate>button.done</button></div>' +
					"</div>" +
					"</div>";

				//uncomment below for alt saturday
				/*scope.editAltSat = function(){
				scope.popupData.isLongWeek = scope.etModel[scope.etModelField].isLongWeek;
				scope.popupData.isAltSat = scope.etModel[scope.etModelField].isAltSat;
          		scope.altSatPopup.setOptions({ anchor: element.find(".altSatBtn") });
	            setTimeout(function(){    //wait after popup closed
	                scope.altSatPopup.open();
	            }, 50);
			}

				scope.saveAltSat = function(){
					scope.etModel[scope.etModelField].isAltSat = scope.popupData.isAltSat;
					scope.etModel[scope.etModelField].isLongWeek = (scope.popupData.isAltSat ? scope.popupData.isLongWeek : false);
					scope.altSatPopup.close();
				}

				scope.altSatPopupOpt = {
		            origin: "bottom right",
		            position: "bottom left",
		            appendTo: element,
		            animation: {
		            	open: {
		            		effects: "slideIn:up"
		            	}
		            }
		        }

		        popupHtml += '<div kendo-popup="altSatPopup" class="altSatPopup" k-options="altSatPopupOpt">'
							   + '<div class="colStart">'
								   + '<label class="toggle"><input type="checkbox" ng-model="popupData.isAltSat" /><div></div><span translate>scheduleCtrl.isAltSat</span></label>'
							   	   + '<label class="toggle" ng-show="popupData.isAltSat"><input type="checkbox" ng-model="popupData.isLongWeek" /><div></div><span translate>scheduleCtrl.isLongWeek</span></label>'
							   + '</div><div class="rowEnd">'
							   	   + '<button class="normalBtn plain greyColor" ng-click="altSatPopup.close()" translate>button.cancel</button>'
							   	   + '<button class="normalBtn lightColor" ng-click="saveAltSat()" type="submit" translate>button.done</button>'
						   	   + '</div>'
						   + '</div>';*/

				if (scope.useCustDay) {
					scope.dayPopupData = {};
					scope.editCustDayData = function(dayKey) {
						scope.dayPopupData.id = null;
						var dayList = scope.etModel.custDayList;
						for (var i = 0; i < dayList.length; i++) {
							if (dayList[i].id === dayKey) {
								scope.dayPopupData.id = dayKey;
								scope.dayPopupData.priority = i;
								scope.dayPopupData.name = dayList[i].name;
								scope.dayPopupData.reachDayEnd = dayList[i].reachDayEnd;
								scope.dayPopupData.dayEndAction = scope.dayPopupData
									.reachDayEnd ? dayList[i].dayEndAction : null;
								scope.dayPopup.setOptions({
									anchor: element.find("#" + dayKey),
								});
								setTimeout(function() { //wait after popup closed
									scope.dayPopup.open();
								}, 50);
								break;
							}
						}
					}
					scope.saveCustDayData = function() {
						var dayList = scope.etModel.custDayList;
						var item = Service.deleteArrItem(dayList, scope.dayPopupData);
						if (item) {
							dayList.splice(scope.dayPopupData.priority, 0, item);
							if (scope.dayPopupData.reachDayEnd) {
								item.dayEndAction = scope.dayPopupData.dayEndAction;
								if (item.dayEndAction)
									scope.updateCustDayEndAction();
							}
						}
						scope.dayPopup.close();
					}

					//for edit popup
					scope.dayEndActions = [{
						value: scope.tsMode.auto,
						text: Service.translate("scheduleCtrl.custDayEndActionOff"),
					}, {
						value: scope.tsMode.noop,
						text: Service.translate("scheduleCtrl.custDayEndActionNone"),
					}];
					scope.priorityDropdownOpt = {
						width: "80px",
						template: "<span>#:data + 1#</span>",
						valueTemplate: "<span>#:data + 1#</span>",
						dataSource: [],
					}
					scope.dayPopupOpt = {
						origin: "bottom right",
						position: "bottom left",
						appendTo: element,
						animation: {
							open: {
								effects: "slideIn:up",
							},
						},
					}

					scope.$watch("etModel.custDayList.length", function(n, o) {
						var arr = [];
						for (var i = 0; i < n; i++)
							arr.push(i);
						scope.priorityDropdown.setDataSource(new kendo.data.DataSource({
							data: arr
						}));
					});

					popupHtml +=
						'<div kendo-popup="dayPopup" class="dayPopup" k-options="dayPopupOpt">' +
						'<div class="rowStart">{{dayPopupData.name}}</div>' +
						'<div class="rowBetween"><span translate>scheduleCtrl.priority</span><div kendo-drop-down-list="priorityDropdown" k-options="priorityDropdownOpt" ng-model="dayPopupData.priority"></div></div>' +
						'<div class="colStart"><label class="radio" ng-repeat="action in dayEndActions"><input type="radio" ng-disabled="!dayPopupData.reachDayEnd" ng-model="dayPopupData.dayEndAction" value="{{action.value}}"/><div></div><span>{{action.text}}</span></label></div>' +
						'<div class="rowBetween">' +
						'<button class="normalBtn plain redColor" ng-click="etModel.removeCustomDay(dayPopupData.id)" translate>button.delete</button>' +
						'<div><button class="normalBtn plain greyColor" ng-click="dayPopup.close()" translate>button.cancel</button><button class="normalBtn lightColor" ng-click="saveCustDayData()" translate>button.done</button></div>' +
						"</div>" +
						"</div>";
				}

				var html = '<div class="legendBlock" ng-if="!etHideLegend">' +
					'<div class="legendColor auto"></div><span translate>scheduleCtrl.autoLegend</span>' +
					'<div class="legendColor manual"></div><span translate>scheduleCtrl.manualLegend</span>' +
					"</div>" +
					'<div class="rowStart stretch">' +
					'<div class="dayBlock"><div ng-repeat="day in days">' +
					(scope.useCustDay ?
						'<span id="{{day.id}}" ng-class="{beforeStar: day.reachDayEnd && !day.dayEndAction}" ng-click="editCustDayData(day.id)"' :
						"<span") + //remove display none below for altSat
					'>{{day.name}}</span><button style="display: none !important;" class="normalBtn plain altSatBtn" ng-class="{long: etModel[etModelField].isLongWeek, on: etModel[etModelField].isAltSat}" ng-if="$index == 5" ng-click="editAltSat()"></button>' +
					'</div></div><div><button class="scrollBtn" ng-click="scroll(true)"></button></div>' +
					"<div class=\"timeBlock\" ng-if=\"etModel[etModelField]\"><div ng-style=\"{width: cellIndexes.length*cellWidth + 'px', 'margin-left': scrollValue + 'px'}\">" +
					'<div class="timeLblRow"><div ng-repeat="lbl in timeLbls track by $index">{{lbl}}</div></div>' +
					'<div class="timeRow" ng-repeat="day in days" data-day-key="{{day.id}}">' +
					'<div ng-repeat="i in cellIndexes" ng-drop="true" ng-drop-success="timeslotOnDrop(day.id, i, $data)">' +
					'<div class="timeSection" ng-if="(ts = getTimeslot(day.id, i))" ng-class="{manual: ts.start.action == tsMode.manual, shortSection: (ts.end.index - i) < 2}"' +
					" ng-style=\"{width: (ts.end.index - i) * cellWidth + 'px'}\"" +
					' ng-drag="true" ng-drag-data="{ts: ts, id: instanceId}" data-start-index="{{i}}">' +
					'<div class="dragHandle" ng-drag-handle></div><div class="clickHandle" ng-click="timeslotOnClick(day.id, i, ts)">{{getTimeStr(ts.start)}}-{{getTimeStr(ts.end)}}</div><div class="resizeHandle"></div>' +
					"</div>" +
					"</div>" +
					"</div></div>" +
					'</div><div><button class="scrollBtn" ng-click="scroll(false)"></button></div>' +
					"</div>";
				var elm = $compile(html + popupHtml)(scope);
				element.append(elm);
			},
		}
	}]).directive("etUpload", ["$compile", "$timeout", "Service", function($compile, $timeout, Service) {
		return {
			restrict: "E",
			scope: {
				// etModel: {
				// 	init(return): func
				//	callback: func(resultStr, fileName)
				// 	resultStr(return): base64 string
				//	fileName(return/init): string
				// }
				etModel: "=",
			},
			link: function(scope, element, attrs) {
				element.addClass("etUpload");

				var acceptedFormat = ["txt", "csv"];

				scope.init = function(errMsg) {
					scope.errMsg = errMsg ? errMsg : null;
					element.find("input:file").val(null);
					scope.etModel.resultStr = null;
					scope.etModel.fileName = null;
					scope.loadingProgress = -1;
					if (scope.etModel.callback) {
						$timeout(function() {
							scope.etModel.callback(scope.etModel.resultStr, scope
								.etModel.fileName);
						});
					}
				}
				scope.etModel.init = scope.init;

				var html = '<div class="rowStart">' +
					'<label class="normalBtn lightColor">' +
					"<span translate>uploadCtrl.upload</span>" +
					'<input type="file">' +
					"</label>" +
					'<div class="loadingIcon" ng-show="loadingProgress != -1 && loadingProgress != 100"></div>' +
					'<span class="fileName" ng-show="loadingProgress == 100">{{etModel.fileName}}</span>' +
					'<span class="errorMsg" ng-show="errMsg != null">{{errMsg}}</span>' +
					"</div>";

				var elm = $compile(html)(scope);
				element.append(elm);

				element.find("input:file").get(0).addEventListener("change", function() {
					if (this.files && this.files[0]) {
						var file = this.files[0];
						var fType = file.name.split(".").pop().toLowerCase();
						scope.errMsg = null;
						if (acceptedFormat.indexOf(fType) == -1) {
							scope.$apply(function() {
								scope.errMsg = Service.translate(
									"uploadCtrl.invalidFormat");
								scope.loadingProgress = -1;
								scope.etModel.fileName = null;
								scope.etModel.resultStr = null;
								if (scope.etModel.callback) {
									$timeout(function() {
										scope.etModel.callback(scope.etModel
											.resultStr, scope.etModel
											.fileName);
									});
								}
							});
							return;
						}

						var FR = new FileReader();
						FR.onloadstart = function(event) {
							scope.$apply(function() {
								scope.loadingProgress = 0;
								scope.etModel.fileName = null;
								scope.etModel.resultStr = null;
							});
						}
						FR.onprogress = function(event) {
							if (event.lengthComputable) {
								scope.$apply(function() {
									scope.loadingProgress = (event.loaded /
										event.total) * 100;
								});
							}
						}
						FR.onloadend = function(event) {
							var error = event.target.error;
							if (error != null) {
								scope.$apply(function() {
									scope.errMsg = error;
									scope.loadingProgress = -1;
									scope.etModel.fileName = null;
									scope.etModel.resultStr = null;
								});
								if (scope.etModel.callback)
									scope.etModel.callback(null, null);
							} else {
								scope.$apply(function() {
									scope.loadingProgress = 100;
									scope.etModel.fileName = file.name;
									scope.etModel.resultStr = event.target
										.result;
								});
								if (scope.etModel.callback)
									scope.etModel.callback(event.target.result, file
										.name);
							}
						}
						FR.readAsDataURL(this.files[0]);
					}
				}, false);
			},
		}
	}]).directive("etDeviceMap", ["$compile", "KEY", "APIKEY", function($compile, KEY,
	APIKEY) { //no pan, will have x y scrollbar
		return {
			restrict: "E",
			scope: {
				etImageUrl: "@",
				etModel: "=", //need an object for 2-way binding to work, ( return fields: selectedMarker, zoomLv, zoomValue, fitSizeZoomLv, fitSizeZoomValue, isDimMode)
				etMarkerDrop: "&", //function(...., $i, $event)
				etMarkerHover: "&", //function(...., $i, $event)
				etMarkerHoverEnd: "&", //function(...., $i, $event)
				etMarkerClick: "&", //function(...., $i, $event)
				etMapClick: "&?", //function(...., $x, $y, $event)
				etContainerClick: "&", //function(...., $event)
				etMapScrolled: "&",
				etModelField: "@", //default: markers
				etTypeField: "@", //default: type
				etDraggable: "=", //default: false
				etDragStart: "&", //function(e) return false to prevent drag
				etHideStatus: "=", //default: false
			},
			link: function(scope, element, attrs) {
				element.addClass("etDeviceMap", scope.etModel);

				scope.APPLICATION_TYPE_INV = APIKEY.applicationTypeInv;
				scope.imgObj = new Image();
				scope.imgStyle = {};
				scope.imgContainerStyle = {};

				scope.etModel.updateZoomValue = function() {
					if (!scope.etModel.zoomLv)
						scope.etModel.zoomLv = 0;
					scope.etModel.zoomValue = Math.pow(KEY.scalePerZoom, scope.etModel.zoomLv);
					scope.imgStyle.transform = "scale(" + scope.etModel.zoomValue + ")";
					scope.imgContainerStyle.width = scope.imgObj.width * scope.etModel
						.zoomValue + "px";
					scope.imgContainerStyle.height = scope.imgObj.height * scope.etModel
						.zoomValue + "px";
				}
				scope.etModel.updateZoomValue();
				if (!scope.etModelField)
					scope.etModelField = "markers"; //floorplanIconSize
				if (!scope.etTypeField)
					scope.etTypeField = "applicationType";

				//公共样式
				scope.canvasStyle = {
					position: 'absolute',
					left: 0,
					top: 0,
					zIndex: 1,
					cursor: 'crosshair',
					lineWidth: 1,
					fillStyle: 'rgba(22, 35, 80,.5)',
					strokeStyle: 'rgba(22, 35, 80,1)',
					fillStyleActive: 'rgba(34, 160, 107,.5)',
					strokeStyleActive: 'rgba(34, 160, 107,1)',
					hoverStyle: 'rgba(34, 160, 107,.5)',
				}
				scope.imgObj.onload = function() {
					scope.$apply(function() {
						scope.imgStyle.height = scope.imgObj.height + "px";
						scope.imgStyle.width = scope.imgObj.width + "px";
						scope.etModel.imgWidth = scope.imgObj.width;
						scope.etModel.imgHeight = scope.imgObj.height;
						scope.imgContainerStyle.height = scope.imgStyle.height;
						scope.imgContainerStyle.width = scope.imgStyle.width;
						scope.imgStyle["background-image"] = 'url("' + scope
							.etImageUrl + '")';
						//zoom to fit
						scope.etModel.zoomLv = 0;
						var maxWidth = element[0].getBoundingClientRect().width;
						var imgWidth = scope.imgObj.width;
						while (imgWidth > maxWidth) {
							imgWidth /= KEY.scalePerZoom;
							scope.etModel.zoomLv--;
						}
						scope.etModel.updateZoomValue();
						scope.etModel.fitSizeZoomLv = scope.etModel.zoomLv;
						scope.etModel.fitSizeZoomValue = scope.etModel.zoomValue;
					});
				}
				// 初始化Canvas
				scope.initCanvasPopup = function() {
					// 生成2个canvas ,一个用于单击绘制线条，另一个用于保存点线

					scope.setCanvasCommonAttr();
					$("#etZoomContainerPopup").find('canvas').remove()
					$('.deviceMap').append(scope.canvas)
					$('.deviceMap').append(scope.canSave)
					$('.deviceMap').append($('#edZoomHilightedView'))
				}
				scope.initCanvas = function() {
					// 生成2个canvas ,一个用于单击绘制线条，另一个用于保存点线
					scope.setCanvasCommonAttr()
					$("#etZoomContainerPopup").find('canvas').remove()
					scope.canvas.id='canvas'
					scope.canSave.id='canvasSave'
					$('#etZoomContainerPopup').append(scope.canvas)
					$('#etZoomContainerPopup').append(scope.canSave)
				}
				//公共属性
				scope.setCanvasCommonAttr=function(){
					scope.canvas = document.createElement('canvas');
					scope.ctx = scope.canvas.getContext('2d');

					scope.canSave = document.createElement("canvas");
					scope.ctxSave = scope.canSave.getContext('2d');

					scope.canvas.height = scope.etModel.imgHeight
					scope.canvas.width = scope.etModel.imgWidth
					scope.canSave.height = scope.etModel.imgHeight
					scope.canSave.width = scope.etModel.imgWidth

					scope.etImage = new Image()
					scope.etImage.onload = function() {
						scope.drawShape();
						// 显示其它station的区域
						scope.canvasSaveReDraw()
					}
					scope.etImage.src = scope.etImageUrl; // 替换为当前背景图片路径

					scope.ctx.strokeStyle = scope.canvasStyle.strokeStyle; //线条颜色
					scope.ctx.lineWidth = scope.canvasStyle.lineWidth; //线条粗细
					scope.ctxSave.strokeStyle = scope.canvasStyle.strokeStyle; //线条颜色
					scope.ctxSave.lineWidth = scope.canvasStyle.lineWidth; //线条粗细

					scope.canvas.style.position = scope.canvasStyle.position
					scope.canvas.style.left = scope.canvasStyle.left
					scope.canvas.style.top = scope.canvasStyle.top
					scope.canvas.style.zIndex = scope.canvasStyle.zIndex
					scope.canSave.style.position = scope.canvasStyle.position
					scope.canSave.style.left = scope.canvasStyle.left
					scope.canSave.style.top = scope.canvasStyle.top
				}
				// 初始绘制图形
				scope.drawShape = function() {
					scope.ctx.clearRect(0, 0, scope.canvas.width, scope.canvas
					.height); // 清空Canvas
					scope.ctxSave.clearRect(0, 0, scope.canSave.width, scope.canSave
					.height); // 清空Canvas
					scope.ctx.drawImage(scope.etImage, 0, 0, scope.canvas.width, scope.canvas
						.height);
					scope.ctxSave.drawImage(scope.etImage, 0, 0, scope.canSave.width, scope
						.canSave.height);
				}

				scope.$watch("etImageUrl", function(n, o) {
					scope.imgObj.src = n;
				});

				scope.$watch("etModel.zoomType", function(n, o) {
					switch (n) {
						case 'in': //放大
							scope.zoomInEdit()
							break;
						case 'out': //缩小
							scope.zoomOutEdit()
							break; //重置
						case 'reset':
							scope.zoomResetEdit()
							break;
						case 'back': //返回上一步
							scope.back()
							break;
					}
					scope.$applyAsync(function() {
						scope.etModel.zoomType = null;
					});
				});
				// 监听当前视图模式：true:获取当前所有的边界坐标并到当前区域 false:清空当前画布
				scope.$watch("etModel.boundaryView", function(n, o) {
					scope.edZoomHilighted = document.getElementById('edZoomHilightedView')
					if (n) {
						scope.initCanvasPopup()
						scope.districtArea()
						$(scope.canvas).off('click')
						scope.global_sx = 1;
						scope.global_sy = 1;
					} else {
						if (scope.ctx) {
							$(".etDeviceMap").find('canvas').remove()
							$(".etDeviceMap").find('canvas').remove()
						}
					}
					if (scope.edZoomHilighted) {
						scope.edZoomHilighted.style.display = 'none';
					}
				})

				// 监听当前编辑模式：true:获取当前所有的边界坐标并到当前区域 false:清空当前画布
				scope.$watch("etModel", function(n, o) {
					if (n.isPosEdit) { //如果是出于编辑模式
						scope.initCanvas()
						// scope.zoomResetEdit()
						scope.districtArea()
						scope.edZoomHilighted = document.getElementById('edZoomHilighted')
					}

				});

				// 监听当前编辑对象
				scope.$watch("etModel.editingNodeData", function(n, o) {
					if (!n) {
						return;
					}
					const {
						floorLocations,
						editingNodeData
					} = scope.etModel
					scope.pointArrCurrent = {}
					scope.getPointArrCurrent(floorLocations, editingNodeData)
				})
				scope.$watch("etModel.floorLocations", function(n, o) {
					if (!n) {
						return;
					}
					const {
						floorLocations,
						editingNodeData
					} = scope.etModel
					scope.pointArrCurrent = {}
					scope.getPointArrCurrent(floorLocations, editingNodeData)
				});

				// 是否执行了坐标删除
				scope.$watch("etModel.isDelete", function(n, o) {

					if (n) {
						tempArr = []
						scope.initCanvas()
						scope.drawShape()
						scope.districtArea()
						scope.canvasSaveReDraw()
					}
				});
				// 鼠标悬停
				scope.$watch("etModel.isHover", function(n, o) {
					if (scope.etModel.boundaryView) {
						scope.pointArrCurrent[scope.etModel.polygonActiveNode.id] = {
							...scope.pointArrCurrent[scope.etModel.polygonActiveNode.id],
							current: n
						}
						scope.drawShape()
						scope.canvasSaveReDraw()

					}
				});
				// 坐标高亮
				scope.$watch("etModel.polygonActiveNode", function(n, o) {
					if (!n) {
						return;
					}
					if (scope.etModel.isHover && scope.etModel.boundaryView) {
						scope.drawShape()
						scope.canvasSaveReDraw(n.id)
					}
				});
				// 组装数据 ，通过传入的floorLocations和editingNodeData
				scope.getPointArrCurrent = function(floorLocations, editingNodeData) {
					floorLocations.forEach(item => {
						let tempObj = {
							...item,
							rectId: item?.id,
							rectArr: [],
							current: false
						}
						const {
							locationPolygon
						} = item
						if (locationPolygon) {
							let tempPolyonArr = locationPolygon.split(',');
							if (tempPolyonArr.length && tempPolyonArr[0].split(' ')
								.length == 1) {
								// 排除svg格式和圆形
								tempPolyonArr = scope.formatConversion(locationPolygon)
									.split(',');
							}
							//把xx xx添加到一个对象中 {x:'',y:}
							tempPolyonArr.forEach(cItem => {
								let tempItem = cItem.split(' ')
								if (tempItem.length == 3) {
									tempObj.rectArr.push({
										x: tempItem[0],
										y: tempItem[1],
										radius: tempItem[2],
										name: item.name
									})
								} else {
									tempObj.rectArr.push({
										x: tempItem[0],
										y: tempItem[1],
										name: item.name
									})
								}
							})
							if (item?.id == editingNodeData.id) {
								tempObj['current'] = true //当前编辑点高亮展示
								scope.rectId = item?.id
							}
							scope.pointArrCurrent[item?.id] = tempObj
						}
					})
				}
				scope.global_sx=1;//default:1
				scope.global_sy=1;//default:1
				scope.isDrawing = false
				let tempObj = {},pointArr = []; //存放坐标的数组
				let tempArr = [],oIndex = -1; //判断鼠标是否移动到起始点处，-1为否，1为是

				// 生成绘图区域
				scope.districtArea = function() {
					var oIndex = -1; //判断鼠标是否移动到起始点处，-1为否，1为是
					$(scope.canvas).mousedown(function(event) {//rect:mousedown
						if(scope.etModel.etDrawWayModel =='rect'){
							scope.isDrawing = true
							scope.pointX = event.offsetX == undefined ? event.layerX /
								scope.global_sx : event.offsetX / scope.global_sx;
							scope.pointY = event.offsetY == undefined ? event.layerY /
								scope.global_sy : event.offsetY / scope.global_sy;
						}
					})
					// 监听鼠标按下事件
					$(scope.canvas).click(function(event) {
            if(scope.etModel.etDrawWayModel =='polygon'){
							if (event.offsetX || event.layerX) {
								scope.pointX = event.offsetX == undefined ? event.layerX /
									scope.global_sx : event.offsetX / scope.global_sx;
								scope.pointY = event.offsetY == undefined ? event.layerY /
									scope.global_sy : event.offsetY / scope.global_sy;
								var piX, piY;
								if (oIndex > 0 && pointArr.length > 0) {
									piX = pointArr[0].x;
									piY = pointArr[0].y;
									//画点
									scope.makearc(scope.ctx, piX * scope.global_sx, piY *
										scope.global_sy, scope.GetRandomNum(4, 4), 0,
										180, scope.canvasStyle.fillStyleActive);
									pointArr.push({
										x: piX,
										y: piY
									});
									tempArr = pointArr

									scope.pointArrCurrent[scope.rectId] = {
										rectId: scope.rectId,
										rectArr: pointArr,
										current: true
									}
									let polygonString = ''
									pointArr.forEach((item, index) => {
										polygonString += item.x + ' ' + item.y + ','
									})
									scope.drawShape();
									// 显示其它station的区域
									scope.canvasSaveReDraw()
									scope.saveCanvas()
									// 当前坐标更新到controller中
									scope.$applyAsync(function() {
										scope.etModel.polygonString = polygonString;
										oIndex = -1
									});
								} else {

									piX = scope.pointX;
									piY = scope.pointY;
									scope.makearc(scope.ctx, piX * scope.global_sx, piY *
										scope.global_sy, scope.GetRandomNum(4, 4), 0,
										180, scope.canvasStyle.fillStyleActive);
									pointArr.push({
										x: piX,
										y: piY
									});
									tempArr = pointArr
									scope.canvasSave(pointArr); //保存点线同步到另一个canvas
								}
							}
						}
					});
					$(scope.canvas).mousemove(function(event) {
						// 是否悬停在矩形内部
						for (var i in scope.pointArrCurrent) {
							var polygon = scope.pointArrCurrent[i];
							if (scope.isPointInPolygon(scope.pointX, scope.pointY,
									polygon) && !polygon.current) {
								var mouseX = event.offsetX == undefined ? event.layerX :
									event.offsetX;
								var mouseY = event.offsetY == undefined ? event.layerY :
									event.offsetY;
								// 设置tooltip的位置
								if (scope.edZoomHilighted) {
									scope.edZoomHilighted.style.left = mouseX + 15 +
										'px';
									scope.edZoomHilighted.style.top = mouseY + 'px';
									scope.edZoomHilighted.style.display = 'block';
								}
								// 当前坐标更新到controller中
								scope.$applyAsync(function() {
									scope.etModel.polygonActiveNode = polygon;
									scope.etModel.isHover = false
								});
								break;
							} else {
								if (scope.edZoomHilighted) {
									scope.edZoomHilighted.style.display = 'none';
								}
							}
						}
						if(scope.etModel.etDrawWayModel =='polygon'){
							if (event.offsetX || event.layerX) {
								scope.pointX = event.offsetX == undefined ? event.layerX /
									scope.global_sx : event.offsetX / scope.global_sx;
								scope.pointY = event.offsetY == undefined ? event.layerY /
									scope.global_sy : event.offsetY / scope.global_sy;
								var piX, piY;
								if (pointArr.length > 0) {
									if ((scope.pointX > pointArr[0].x - 15 && scope.pointX <
										pointArr[0].x + 15) && (scope.pointY > pointArr[
											0].y - 15 && scope.pointY < pointArr[0].y +
										15)) {
										if (pointArr.length > 1) {
											piX = pointArr[0].x;
											piY = pointArr[0].y;
											scope.ctx.clearRect(0, 0, scope.canvas.width,
												scope.canvas.height);
											scope.ctxSave.clearRect(0, 0, scope.canvasSave
												.width, scope.canvasSave.height);
											scope.makearc(scope.ctxSave, piX * scope
												.global_sx, piY * scope.global_sy, scope
												.GetRandomNum(4, 4), 0, 180, scope
												.canvasStyle.fillStyleActive);
											oIndex = 1;
										}
									} else {
										piX = scope.pointX;
										piY = scope.pointY;
										oIndex = -1;
									}
									/*开始绘制*/
									scope.ctx.beginPath();
									scope.ctx.moveTo(pointArr[0].x * scope.global_sx,
										pointArr[0].y * scope.global_sy);
									if (pointArr.length > 1) {
										for (var i = 1; i < pointArr.length; i++) {
											scope.ctx.lineTo(pointArr[i].x * scope
												.global_sx, pointArr[i].y * scope
												.global_sy);
										}
									}
									scope.ctx.fillStyle = scope.canvasStyle
										.fillStyleActive; //填充颜色
									scope.ctx.strokeStyle = scope.canvasStyle
										.strokeStyleActive
									scope.ctx.stroke(); //绘制
								}
							} else {
								if (tempArr[tempArr.length - 1]) {
									scope.pointX = tempArr[tempArr.length - 1].x / scope
										.global_sx
									scope.pointY = tempArr[tempArr.length - 1].y / scope
										.global_sy
									pointArr = tempArr
									scope.drawShape()
									scope.canvasSaveReDraw()
								}
							}
						}else if(scope.etModel.etDrawWayModel =='rect'){
							if (!scope.isDrawing) return;
              const currentX = event.offsetX == undefined ? event.layerX /
                scope.global_sx : event.offsetX / scope.global_sx;
              const currentY = event.offsetY == undefined ? event.layerY /
                scope.global_sy : event.offsetY / scope.global_sy;
							scope.endX = currentX;
							scope.endY = currentY;
							scope.rectDrawingFill()
						}

					})
					$(scope.canvas).mouseup(function() {//only etDrawWayModel == 'rect
						if(scope.etModel.etDrawWayModel=='rect'){
							if (scope.isDrawing) {
								scope.isDrawing = false;
								pointArr = [
									{ x: scope.pointX, y: scope.pointY },
									{ x: scope.endX, y: scope.pointY },
									{ x: scope.endX, y: scope.endY },
									{ x: scope.pointX, y: scope.endY},
									{ x: scope.pointX, y: scope.pointY },
								];
								scope.pointArrCurrent[scope.rectId] = {
									rectId: scope.rectId,
									rectArr: pointArr,
									current: true,
									type:'rect'
								}
								let polygonString = ''
								pointArr.forEach((item, index) => {
									polygonString += item.x + ' ' + item.y + ','
								})
								scope.drawShape();
								scope.canvasSaveReDraw()
								scope.saveCanvas()
								// 当前坐标更新到controller中
								scope.$applyAsync(function() {
									scope.etModel.polygonString = polygonString;
									oIndex = -1
								});
							}
						}
					})
				}
			  scope.rectDrawingFill=function(){
					// 高亮显示目标多边形
					scope.ctx.fillStyle = scope.canvasStyle.strokeStyleActive;
					scope.ctxSave.fillStyle = scope.canvasStyle.strokeStyleActive;
					scope.ctx.strokeStyle = scope.canvasStyle.strokeStyleActive;
					scope.ctxSave.strokeStyle = scope.canvasStyle.strokeStyleActive;

					scope.ctx.clearRect(0, 0, scope.canvas.width, scope.canvas.height);
					scope.ctxSave.clearRect(0, 0, scope.canvasSave.width,  scope.canvasSave.height);
					scope.ctx.fillRect(scope.pointX, scope.pointY, scope.endX - scope.pointX, scope.endY - scope.pointY);
					scope.ctxSave.fillRect(scope.pointX, scope.pointY, scope.endX - scope.pointX, scope.endY - scope.pointY);
				}
				scope.canvasSaveReDraw = function(currentId) {
					// 遍历多边形数组，绘制多边形
					let pointArrCurrent = scope.pointArrCurrent
					for (let item in pointArrCurrent) {
						var polygon = pointArrCurrent[item];
						if (currentId) {
							polygon.current = false
							if (polygon['id'] == currentId) {
								polygon['current'] = true
							}
						}
						// 设置颜色
						if (polygon.current) {
							// 高亮显示目标多边形
							scope.ctx.fillStyle = scope.canvasStyle.hoverStyle;
							scope.ctxSave.fillStyle = scope.canvasStyle.hoverStyle;
							scope.ctx.strokeStyle = scope.canvasStyle.hoverStyle;
							scope.ctxSave.strokeStyle = scope.canvasStyle.hoverStyle;
						} else {
							// 其他多边形使用默认颜色
							scope.ctx.fillStyle = scope.canvasStyle.fillStyle;
							scope.ctxSave.fillStyle = scope.canvasStyle.fillStyle;
							scope.ctx.strokeStyle = scope.canvasStyle.strokeStyle;
							scope.ctxSave.strokeStyle = scope.canvasStyle.strokeStyle;
						}
						if (polygon?.rectArr?.length) {
							// 开始绘制多边形路径
							scope.ctx.beginPath();
							scope.ctx.moveTo(polygon.rectArr[0].x * scope.global_sx, polygon
								.rectArr[0].y * scope.global_sy);
							scope.ctxSave.beginPath();
							scope.ctxSave.moveTo(polygon.rectArr[0].x * scope.global_sx, polygon
								.rectArr[0].y * scope.global_sy);
							// 绘制多边形的边
							for (var j = 1; j < polygon.rectArr.length; j++) {
								scope.ctx.lineTo(polygon.rectArr[j].x * scope.global_sx, polygon
									.rectArr[j].y * scope.global_sy);
								scope.ctxSave.lineTo(polygon.rectArr[j].x * scope.global_sx,
									polygon.rectArr[j].y * scope.global_sy);
							}
							// 闭合路径并填充颜色
							scope.ctx.closePath();
							scope.ctx.fill();
							scope.ctx.stroke();
							scope.ctxSave.closePath();
							scope.ctxSave.fill();
							scope.ctxSave.stroke();
						}
					}
				}
				// 放大图形
				scope.zoomInEdit = function() {
					if (scope.edZoomHilighted) {
						scope.edZoomHilighted.style.display = 'none';
					}
					var fator = 2;
					scope.global_sx = scope.global_sx * fator;
					scope.global_sy = scope.global_sy * fator;
					scope.ctx.scale(2, 2); // 在x和y方向上放大2倍
					scope.ctxSave.scale(2, 2); // 在x和y方向上放大2倍
					scope.canvas.width = scope.canvas.width * 2
					scope.canvas.height = scope.canvas.height * 2
					scope.canSave.width = scope.canSave.width * 2
					scope.canSave.height = scope.canSave.height * 2
					scope.drawShape();
					// 显示其它station的区域
					scope.canvasSaveReDraw()
					scope.saveCanvas()
				}
				scope.zoomResetEdit = function() {
					if (scope.edZoomHilighted) {
						scope.edZoomHilighted.style.display = 'none';
					}
					scope.global_sx = 1;
					scope.global_sy = 1;
					scope.ctx.scale(1, 1);
					scope.ctxSave.scale(1, 1);
					if(scope.etModel.boundaryView){
						$(".etDeviceMap").find('canvas').remove()
						$(".etDeviceMap").find('canvas').remove()
						scope.initCanvasPopup()
						scope.districtArea()
					}else{
						scope.initCanvas()
						scope.districtArea()
					}
				}

				scope.zoomOutEdit = function() {
					if (scope.edZoomHilighted) {
						scope.edZoomHilighted.style.display = 'none';
					}
					var fator = 2;
					scope.global_sx = scope.global_sx / fator;
					scope.global_sy = scope.global_sy / fator;
					scope.ctx.scale(0.5, 0.5); // 在x和y方向上放大2倍
					scope.ctxSave.scale(0.5, 0.5); // 在x和y方向上放大2倍
					scope.canvas.width = scope.canvas.width * 0.5
					scope.canvas.height = scope.canvas.height * 0.5
					scope.canSave.width = scope.canSave.width * 0.5
					scope.canSave.height = scope.canSave.height * 0.5
					scope.drawShape();
					// 显示其它station的区域
					scope.canvasSaveReDraw()
					scope.saveCanvas()
				}

				// 返回上一步
				scope.back = function(e) {
					tempArr = tempArr.slice(0, tempArr.length - 1)
					scope.pointArrCurrent[scope.rectId]['rectArr'] = tempArr
					scope.drawShape()
					// 显示其它station的区域
					scope.canvasSaveReDraw()
					scope.saveCanvas()
					$(scope.canvas).trigger('mousemove')
				}
				// 判断点是否再坐标内
				scope.isPointInPolygon = function(mouseX, mouseY, polygon) {
					if (polygon?.rectArr?.length) {
						var minX = Number.MAX_VALUE;
						var maxX = Number.MIN_VALUE;
						var minY = Number.MAX_VALUE;
						var maxY = Number.MIN_VALUE;
						var vertices = polygon.rectArr
						for (var i = 0; i < vertices.length; i++) {
							minX = Math.min(minX, vertices[i].x);
							maxX = Math.max(maxX, vertices[i].x);
							minY = Math.min(minY, vertices[i].y);
							maxY = Math.max(maxY, vertices[i].y);
						}
						return mouseX >= minX && mouseX <= maxX && mouseY >= minY && mouseY <=
							maxY;
					}

					return false
				}
				/*canvas生成圆点*/
				scope.GetRandomNum = function(Min, Max) {
					var Range = Max - Min;
					var Rand = Math.random();
					return (Min + Math.round(Rand * Range));
				}
				scope.makearc = function(ctx, x, y, r, s, e, color) {
					scope.ctx.clearRect(0, 0, scope.canvas.width, scope.canvas.height); //清空画布
					scope.ctx.beginPath();
					scope.ctx.fillStyle = color;
					scope.ctx.arc(x, y, r, s, e);
					scope.ctx.fill();
				}
				// svg和canvas转换
				scope.formatConversion = function(loc) {
					let arr = loc.split(' ')
					let result = ''
					arr.forEach((item, index) => {
						result += item.split(',').join(' ') + ','
					})
					// 去掉字符串的最后一个逗号
					result = result.substring(0, result.lastIndexOf(','))
					return result;
				}

				// 存储已生成的点线
				scope.canvasSave = function(pointArr) {
					scope.ctx.beginPath();
					if (pointArr.length > 1) {
						scope.ctx.moveTo(pointArr[0].x * scope.global_sx, pointArr[0].y * scope
							.global_sy);
						for (var i = 1; i < pointArr.length; i++) {
							scope.ctx.fillStyle = 'rgba(23, 43, 58,.3)'; //填充颜色
							scope.ctx.stroke(); //绘制
						}
						scope.ctx.closePath();
					}
				}

				/*生成画布 结束绘画*/
				scope.saveCanvas = function() {
					scope.ctxSave.closePath(); //结束路径状态，结束当前路径，如果是一个未封闭的图形，会自动将首尾相连封闭起来
					scope.ctxSave.stroke(); //绘制
					scope.ctx.closePath(); //结束路径状态，结束当前路径，如果是一个未封闭的图形，会自动将首尾相连封闭起来
					scope.ctx.stroke(); //绘制
					pointArr = [];
				}

				// 清空选区
				scope.areaClear = function() {
					scope.ctx.clearRect(0, 0, scope.canvas.width, scope.canvas.height);
					scope.ctxSave.clearRect(0, 0, scope.canSave.width, scope.canSave.height);
					pointArr = [];
				}
				scope.zoomIn = function() {
					scope.etModel.zoomLv++;
					scope.etModel.updateZoomValue();
				}
				scope.zoomOut = function() {
					scope.etModel.zoomLv--;
					scope.etModel.updateZoomValue();
				}

				scope.zoomReset = function() {
					scope.etModel.zoomLv = 0;
					scope.etModel.updateZoomValue();
				}
				scope.toggleDimMode = function() {
					scope.etModel.isDimMode = !scope.etModel.isDimMode;
				}
				scope.dragOpt = {
					autoScroll: true,
					distance: 1,
					filter: ".marker:not(.noDrag)",
					cursorOffset: {
						top: 0,
						left: 0
					},
					hint: function(e) {
						var arr = e.css("transform").split(",");
						if (arr.length == 6) {
							arr[3] = scope.etModel.zoomValue;
							arr[0] = "matrix(" + scope.etModel.zoomValue;
							return $('<div class="hint ' + e.attr("class") +
								'" style="transform: ' + arr.join() + '"></div>');
						} else {
							console.log("don't support this transfrom", arr);
						}
					},
					dragstart: function(e) {
						if (!scope.etDraggable || false === scope.etDragStart({
								$i: e.currentTarget.data("index"),
								$event: e
							})) {
							e.preventDefault();
							return;
						}
						e.currentTarget.hide();
					},
					dragend: function(e) {
						console.log('dragend')
						e.currentTarget.show();
					},
				}
				scope.dropOpt = {
					drop: function(e) {
						var i = e.draggable.currentTarget.data("index");
						if (i != null) {
							var d = scope.etModel[scope.etModelField][i];
						} else {
							console.error("index not found");
							return;
						}
						var size = e.draggable.currentTarget.width();
						var targetOffset = angular.element(e.dropTarget).offset();
						scope.$apply(function() {
							if (d.location == null)
								d.location = {};
							d.location.x = (e.pageX - targetOffset.left) / scope
								.etModel.zoomValue; // - e.offsetX + size / 2;
							d.location.y = (e.pageY - targetOffset.top) / scope
								.etModel.zoomValue; // - e.offsetY + size / 2;
							scope.etModel.selectedMarker = d;
						});
						scope.etMarkerDrop({
							$i: i,
							$event: e
						});
					},
				}

				scope.onDeviceClick = function($index, $event) {

					$event.stopPropagation();

					scope.etModel.selectedMarker = scope.etModel[scope.etModelField][$index];
					scope.etMarkerClick({
						$i: $index,
						$event: $event
					});
				}
				scope.onMapClick = function($event) {
					scope.etModel.selectedMarker = null;
					if (scope.etMapClick) {
						var pos = angular.element($event.currentTarget).offset();
						pos.left = ($event.pageX - pos.left) / scope.etModel.zoomValue;
						pos.top = ($event.pageY - pos.top) / scope.etModel.zoomValue;
						scope.etMapClick({
							$event: $event,
							$x: pos.left,
							$y: pos.top
						});
						$event.stopPropagation();
					}

				}
				scope.onDeviceHover = function($event) {
					var target = angular.element($event.target);
					if (target.hasClass("marker")) {
						var i = target.data("index");
						var d = scope.etModel[scope.etModelField][i];
						var screenPos = target.parent().get(0).getBoundingClientRect();
						scope.anchorPos = {
							left: d.location.x * scope.etModel.zoomValue + screenPos.left +
								"px",
							top: d.location.y * scope.etModel.zoomValue + screenPos.top +
								"px",
							transform: "translate(-50%, -50%) scale(" + scope.etModel
								.zoomValue + ")",
						}

						if (attrs.etMarkerHover)
							scope.etMarkerHover({
								$i: i,
								$event: $event
							});
					}
				}
				scope.onDeviceHoverEnd = function($event) {
					if (attrs.etMarkerHoverEnd) {
						var target = angular.element($event.target);
						if (target.hasClass("marker"))
							scope.etMarkerHoverEnd({
								$i: target.data("index"),
								$event: $event
							});
					}
				}

				scope.onContainerClick = function($event) {
					scope.etContainerClick({
						$event: $event
					});
				}
				var html = '<div class="tooltipAnchor" ng-style="anchorPos"><div></div></div>' +
					'<button class="dimBtn" ng-show="!etModel.boundaryView" ng-class="{dim: etModel.isDimMode}" ng-click="toggleDimMode()"></button>' +
					'<div class="zoomControl colCenter">' +
					'<button class="zoomInBtn" ng-click="etModel.boundaryView||etModel.isPosEdit?zoomInEdit():zoomIn()"></button>' +
					'<button class="zoomOutBtn" ng-click="etModel.boundaryView||etModel.isPosEdit?zoomOutEdit():zoomOut()"></button>' +
					'<button class="zoomResetBtn" ng-click="etModel.boundaryView?zoomResetEdit():zoomReset()"><div></div><div></div></button>' +
					'</div><div kendo-draggable k-options="dragOpt" class="deviceMap" ng-click="onContainerClick($event)"' +
					(attrs.etMapScrolled ? ' et-scrolled="etMapScrolled()">' : '>') +
					'<div ng-style="imgContainerStyle">' +
					'<div kendo-drop-target  ng-show="!etModel.boundaryView" k-options="dropOpt" ng-mouseover="onDeviceHover($event)" ng-mouseout="onDeviceHoverEnd($event)" ng-style="imgStyle">' +
					'<div data-index="{{$index}}" ng-click="onDeviceClick($index, $event)" ng-show="m.location.x != null" class="marker{{true || etHideStatus || m.online ? \'\' : \' offline\'}} {{APPLICATION_TYPE_INV[(m[etTypeField])]}} {{etDraggable ? \'\' : \'noDrag\'}} {{m.cssClass}}" ' +
					'ng-repeat="m in etModel[etModelField]" ng-style="{left: m.location.x+\'px\', top: m.location.y+\'px\'}" ></div>' //TODOricky hide offline
					+
					'<div class="dimLayer"  ng-class="{dim: etModel.isDimMode}" ng-click="onMapClick($event)"></div>' +
					'</div>' +
					'</div>' +
					'</div>';
				var elm = $compile(html)(scope);
				element.append(elm);
			},
		}
	}]).directive("etPanZoom", ["$compile", "$timeout", "KEY", function($compile, $timeout, KEY) {
		//pan will also trigger click, db click will also trigger click on child element
		return {
			restrict: "E",
			transclude: true,
			scope: {
				etModel: "=", //( return function: zoom, centerAt)
				etInitialCenter: "=", //{x,y}: specific point, default: no center
				etOverlayHtml: "=", // html str
			},
			template: '<div class="zoomControl colCenter">' +
				'<button class="zoomInBtn" ng-click="zoom(true)"></button>' +
				'<button class="zoomOutBtn" ng-click="zoom()"></button>' + "</div>" +
				'<div class="panZoomRoot" ng-transclude></div>',
			link: function(scope, element, attrs) {
				element.addClass("etPanZoom");

				var elm = element.get(0);

				scope.zoom = function(isZoomIn) {
					scope.pz.smoothZoom(elm.offsetWidth / 2, elm.offsetHeight / 2, isZoomIn ?
						KEY.scalePerZoom : 1 / KEY.scalePerZoom);
				}

				scope.centerAt = function(loc) {
					scope.pz.moveTo(elm.offsetWidth / 2 - loc.x * scope.pz.getTransform().scale,
						elm.offsetHeight / 2 - loc.y * scope.pz.getTransform().scale);
				}

				if (scope.etModel) {
					scope.etModel.zoom = scope.zoom;
					scope.etModel.centerAt = scope.centerAt;
				}

				scope.pz = panzoom(element.children(".panZoomRoot").get(0), {
					bounds: true,
					boundsPadding: 0.1,
					maxZoom: KEY.zoomMax,
					minZoom: KEY.zoomMin,
					zoomDoubleClickSpeed: 1, //1 = disable db click zoom
				});

				if (scope.etInitialCenter && typeof scope.etInitialCenter === "object") {
					$timeout(function() {
						scope.centerAt(scope.etInitialCenter);
					});
				}

				scope.$on("$destroy", function() {
					scope.pz.dispose();
				});

				if (scope.etOverlayHtml)
					element.append(scope.etOverlayHtml);
			},
		}
	}]).directive("etTable", ["KEY", function(KEY) {
		return {
			restrict: "E",
			link: function(scope, element, attrs) {
				element.addClass("etTable");
				$(".etTable .etTableHead").css({
					"padding-right": KEY.scrollBarWidth + "px",
				});
			},
		}
	}]).directive("etStatusControls", ["$compile", function($compile) {
		return {
			restrict: "E",
			scope: {
				etNode: "=", //node object to be controlled
				etShowLoading: "=?", //show loading icon or not, default: true
				etLoadingField: "=?", //default name: isLoading, can be array
				etDisconnectFunc: "&", //function(..., $node)
				etDisconnectField: "@", //default name: count.offline
			},
			link: function(scope, element, attrs) {
				element.addClass("etStatusControls");

				if (attrs.etDisconnectFunc) {
					if (!scope.etDisconnectField)
						scope.etDisconnectField = "count.offline";
					scope.onClickDisconnect = function() {
						scope.etDisconnectFunc({
							$node: scope.etNode
						});
					}
					//TODOricky hide offline
					// element.prepend($compile('<button ng-if="etNode.' + scope.etDisconnectField + '" class="errorBtn" ng-click="onClickDisconnect()"></button>')(scope));
				}

				if (scope.etShowLoading === undefined)
					scope.etShowLoading = true;
				if (scope.etShowLoading) {
					if (Array.isArray(scope.etLoadingField)) {
						if (scope.etLoadingField.length == 0)
							scope.etLoadingField = ["isLoading"];
						element.prepend($compile(
							'<div class="partialLoadingIcon" ng-show="etNode.' + scope
							.etLoadingField.join(" || etNode.") + '"></div>')(scope));
					} else {
						if (!scope.etLoadingField)
							scope.etLoadingField = "isLoading";
						element.prepend($compile(
							'<div class="partialLoadingIcon" ng-show="etNode[etLoadingField]"></div>'
							)(scope));
					}
				}
			},
		}
	}]).directive("etTemperatureBtn", ["$compile", "APIKEY", function($compile, APIKEY) {
		return {
			restrict: "E",
			scope: {
				etNode: "=", //node object to be controlled
				etControlFunc: "&", //function(..., $node, $action)
				etLongBtn: "=", //if false, button label is removed, default: false
				etColumnDisplay: "=", //if true, align button in a column, default: false
			},
			link: function(scope, element, attrs) {
				element.addClass("etTemperatureBtn");

				scope.onClickTemperature = function(cooler) {
					scope.etControlFunc({
						$node: scope.etNode,
						$action: cooler ? APIKEY.action.cooler : APIKEY.action.warmer,
					});
				}

				var clsSuffix = scope.etLongBtn ? "" : "Only";
				var warmLbl = scope.etLongBtn ? "button.warmer" : "";
				var coolLbl = scope.etLongBtn ? "button.cooler" : "";
				var html =
					'<button ng-disabled="etNode.isCooling || etNode.isWarming || etNode.isCoolingTemp || etNode.isWarmingTemp" class="normalBtn border round lightColor warmer' +
					clsSuffix +
					'" ng-click="onClickTemperature(false)" translate>' +
					warmLbl +
					"</button>" +
					'<button ng-disabled="etNode.isCooling || etNode.isWarming || etNode.isCoolingTemp || etNode.isWarmingTemp" class="normalBtn border round lightColor cooler' +
					clsSuffix +
					'" ng-click="onClickTemperature(true)" translate>' +
					coolLbl +
					"</button>";
				if (!scope.etColumnDisplay)
					html = '<div class="rowStart">' + html + "</div>";
				html +=
					'<div ng-show="etNode.isWarming || etNode.isCooling" translate>label.adjustingTemperature</div>';

				var elm = $compile(html)(scope);
				element.append(elm);
			},
		}
	}]);
})();